export const APPS: any = {
  cpt: {
    content: {
      description: {
        au: 'Discover how SafetyCulture can help with improving your industry processes and see how we compare to other solutions and <a href="https://safetyculture.com/alternatives/">software options</a> to find your best fit.',
        de: 'Entdecken Sie, wie SafetyCulture Ihnen bei der Verbesserung Ihrer branchenspezifischen Abläufe helfen kann, und wie wir im Vergleich zu anderen Lösungen und <a href="https://safetyculture.com/alternatives/">Softwareoptionen</a> abschneiden.',
        en: 'Discover how SafetyCulture can help with improving your industry processes and see how we compare to other solutions and <a href="https://safetyculture.com/alternatives/">software options</a> to find your best fit.',
        es: 'Descubra cómo puede ayudarle SafetyCulture a mejorar los procesos en su industria y vea nuestra comparación con otras soluciones y <a href="https://safetyculture.com/alternatives/">alternativas de software</a> para encontrar la opción más adecuada.',
        fr: 'Découvrez comment SafetyCulture peut vous aider à améliorer vos processus industriels et faites la comparaison avec les autres solutions et <a href="https://safetyculture.com/alternatives/">options logicielles</a> pour trouver celle qui vous convient.',
        nl: 'Ontdek hoe SafetyCulture u kan helpen uw industriële processen te verbeteren, en vergelijk onze oplossingen en <a href="https://safetyculture.com/alternatives/">software-opties</a> met die van anderen, om te vinden wat best bij u past.',
        'pt-br':
          'Descubra como a SafetyCulture pode ajudar a melhorar os processos do seu setor, e veja como nos comparamos a outras soluções e <a href="https://safetyculture.com/alternatives/">opções de software</a> para que você possa encontrar a melhor opção.',
        us: 'Discover how SafetyCulture can help with improving your industry processes and see how we compare to other solutions and <a href="https://safetyculture.com/alternatives/">software options</a> to find your best fit.',
      },
      title: {
        au: 'Inspection Apps & Software Guides',
        de: 'Prüfungs-Apps & Software-Ratgeber',
        en: 'Inspection Apps & Software Guides',
        es: 'Aplicaciones de inspección y guías de software',
        fr: "Applications d'inspection et guides logiciels",
        nl: 'Inspectie-apps & Softwarehandleidingen',
        'pt-br': 'Aplicativos de Inspeção e Guias de Softwares',
        us: 'Inspection Apps & Software Guides',
      },
    },
    graphql: {
      all: 'apps',
      on: 'App',
      post: 'app',
    },
    seo: {
      description: {
        au: 'Discover the best inspection apps and software that can help improve and simplify your business processes. Lead with continuous improvement, quality, efficiency, and safety with these technologies today.',
        de: 'Entdecken Sie die besten Apps und Software für Inspektionen, mit denen Sie Ihre Unternehmensabläufe verbessern und vereinfachen können. Sorgen Sie mit diesen Technologien noch heute für kontinuierliche Verbesserung, Qualität, Effizienz und Sicherheit.',
        en: 'Discover the best inspection apps and software that can help improve and simplify your business processes. Lead with continuous improvement, quality, efficiency, and safety with these technologies today.',
        es: 'Descubra los mejores software y aplicaciones de inspección para a mejorar y simplificar los procesos comerciales. Lidere hoy a través de mejoras continuas, calidad, eficiencia y seguridad con estas tecnologías.',
        fr: "Découvrez les meilleurs logiciels et applications d'inspection pour améliorer et simplifier vos processus d'entreprise. Améliorez en permanence la qualité, l'efficacité et la sécurité de vos opérations avec ces technologies.",
        nl: 'Ontdek de beste inspectie-apps en -software die u kunnen helpen bij het verbeteren en vereenvoudigen van uw bedrijfsprocessen. Neem vandaag met deze technologieën het voortouw als gevolg van continue verbeteringen, kwaliteit, efficiëntie en veiligheid.',
        'pt-br':
          'Descubra os melhores aplicativos de inspeção e software que podem ajudar a melhorar e simplificar os processos do seu negócio. Lidere com uma melhoria, qualidade, eficiência e segurança contínua com estas tecnologias nos dias de hoje.',
        us: 'Discover the best inspection apps and software that can help improve and simplify your business processes. Lead with continuous improvement, quality, efficiency, and safety with these technologies today.',
      },
      title: {
        au: 'Best Inspection Apps & Software Guides | SafetyCulture',
        de: 'Leitfäden für die besten Apps und Software für Inspektionen | SafetyCulture',
        en: 'Best Inspection Apps & Software Guides | SafetyCulture',
        es: 'Guías de los mejores software y aplicaciones de inspección | SafetyCulture',
        fr: "Guide des meilleures applications et logiciels d'inspection | SafetyCulture",
        nl: 'Beste gidsen voor inspectie-apps & -software | SafetyCulture',
        'pt-br':
          'Melhores Aplicativos de Inspeção e Guias de Software I SafetyCulture',
        us: 'Best Inspection Apps & Software Guides | SafetyCulture',
      },
    },
    slug: {
      au: 'app',
      de: 'app',
      en: 'app',
      es: 'aplicacion',
      fr: 'appli',
      nl: 'app',
      'pt-br': 'aplicativo',
      us: 'app',
    },
    taxonomy: 'taxonomyCategories',
    title: {
      au: 'App',
      de: 'App',
      en: 'App',
      es: 'App',
      fr: 'Appli',
      nl: 'App',
      'pt-br': 'App',
      us: 'App',
    },
  },
  template: {
    app: {
      articles: {
        au: 'Related articles',
        de: 'Ähnliche Artikel',
        en: 'Related articles',
        es: 'Artículos relacionados',
        fr: 'Articles connexes',
        nl: 'Verwante artikelen',
        'pt-br': 'Artigos relacionados',
        us: 'Related articles',
      },
      author: {
        au: 'Article by',
        de: 'Artikel von',
        en: 'Article by',
        es: 'Artículo de',
        fr: 'Article par',
        nl: 'Artikel door',
        'pt-br': 'Artigo por',
        us: 'Article by',
      },
      explore: {
        au: 'Explore more templates',
        de: 'Weitere kostenlose Vorlagen',
        en: 'Explore more templates',
        es: 'Explore más plantillas',
        fr: 'Découvrez plus de modèles',
        nl: 'Ontdek meer sjablonen',
        'pt-br': 'Explore mais modelos',
        us: 'Explore more templates',
      },
      read: {
        au: 'Read more',
        de: 'Weiterlesen',
        en: 'Read more',
        es: 'Leer más',
        fr: 'Lire la suite',
        nl: 'Meer informatie',
        'pt-br': 'Leia mais',
        us: 'Read more',
      },
    },
    apps: {
      more: {
        au: 'Find out more',
        de: 'Mehr erfahren',
        en: 'Find out more',
        es: 'Más información',
        fr: 'Découvrez-en plus',
        nl: 'Meer info',
        'pt-br': 'Saiba mais',
        us: 'Find out more',
      },
      pagination: 12,
    },
  },
  seoTypes: ['apps', 'checklists', 'topics'],
  types: {
    apps: {
      title: {
        au: 'App',
        de: 'App',
        en: 'App',
        es: 'App',
        fr: 'Appli',
        nl: 'App',
        'pt-br': 'App',
        us: 'App',
      },
      posts: {
        au: 'No posts',
        de: 'Keine Beiträge',
        en: 'No posts',
        es: 'No hay publicaciones',
        fr: 'Aucune publication',
        nl: 'Geen berichten',
        'pt-br': 'Não há postagens',
        us: 'No posts',
      },
    },
    checklists: {
      title: {
        au: 'Checklists',
        de: 'Checklisten',
        en: 'Checklists',
        es: 'Listas de verificación',
        fr: 'Listes de vérification',
        nl: 'Checklists',
        'pt-br': 'Listas de verificação',
        us: 'Checklists',
      },
    },
    topics: {
      title: {
        au: 'Topics',
        de: 'Themen',
        en: 'Topics',
        es: 'Temas',
        fr: 'Thèmes',
        nl: 'Onderwerpen',
        'pt-br': 'Tópicos',
        us: 'Topics',
      },
    },
  },
};
