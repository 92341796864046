import styles from './Apps.module.scss';

import Card from '@/components/molecules/Card';
import Column from '@shared/components/atoms/Column';
import Container from '@shared/components/atoms/Container';
import Row from '@shared/components/atoms/Row';
import Typography from '@shared/components/atoms/Typography';
import typeTypes from '@/utilities/types/Types';
import { APPS } from '@/utilities/constants/Apps';
import { useLocale } from '@/utilities/contexts/Locale';
import typeApps from '@/utilities/types/Apps';
import DOMPurify from 'isomorphic-dompurify';

interface AppsProps {
  content: {
    apps: typeApps[];
    types: typeTypes;
  };
}

const Apps = (props: AppsProps) => {
  const { content } = props;

  const locale: string = useLocale();

  return (
    <div className={styles.root}>
      <div className={styles.hero}>
        <Container>
          <Typography
            className={styles.title}
            family="poppins"
            tag="h1"
            xs={7}
            md={8}
            lg={9}
          >
            {APPS.cpt.content.title[locale]}
          </Typography>
          <Row justify="center">
            <Column xs={12} lg={8}>
              <Typography
                xs={3}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    APPS.cpt.content.description[locale],
                  ),
                }}
              />
            </Column>
          </Row>
        </Container>
      </div>
      <Container>
        <div className={styles.posts}>
          {content.apps && content.apps.length ? (
            <Row xs={2}>
              {content.apps.map((app) => (
                <Column key={app.id} xs={12} md={6} lg={4}>
                  <Card
                    card={[
                      { image: app.featuredImage && app.featuredImage.node },
                      { title: app.title },
                      {
                        editor: app.acfApp?.heroEditor
                          ? app.acfApp?.heroEditor
                          : app?.seo && app.seo.metaDesc,
                      },
                      {
                        links: [
                          {
                            link: {
                              title: APPS.template.apps.more[locale],
                              url: app.uri,
                            },
                            style: 'secondary',
                          },
                        ],
                      },
                    ]}
                    categories={app?.taxonomyCategories.nodes}
                    customise={{
                      alignment: 'left',
                      link: {
                        title: app.title,
                        url: app.uri,
                      },
                    }}
                    heading="h2"
                    stretch={true}
                    type={content.types.locale}
                  />
                </Column>
              ))}
            </Row>
          ) : (
            <Typography xs={3}>{APPS.types.apps.posts[locale]}</Typography>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Apps;
